import React from "react";
import HeadingAndByline from "../components/knowledgeCentre/HeadingAndByline";
import Videos from "../components/knowledgeCentre/Videos";
import KnowledgeCentrePage from "../templates/KnowledgeCentrePage";

const seo = {
  title: "Videos",
  description:
    "Enjoy our educational videos covering some of the fundamentals of materials science, mechanics and mechanical testing.",
};

function VideosPage() {
  return (
    <KnowledgeCentrePage seo={seo} linkPath="/videos">
      <Videos />
    </KnowledgeCentrePage>
  );
}

export default VideosPage;
