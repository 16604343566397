import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import {
  heading100,
  heading300,
  paragraphTextSmall,
} from "../../stitches/combinedStyles";

import { styled } from "../../stitches/stitches.config";
import { slugify } from "../../utils/slugify";
import Card from "../shared/Card/Card";

const title = `Videos`;
const byline = `Enjoy our educational videos covering some of the fundamentals of materials science, mechanics and mechanical testing.`;

const Videos = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/videos/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              link
              title
              byline
            }
          }
        }
      }
    }
  `);
  return (
    <Container>
      <HeadingContainer>
        <Title>{title}</Title>
        <Byline>{byline}</Byline>
      </HeadingContainer>
      <VideosContainer>
        {data.allMarkdownRemark.edges.map((edge: any) => {
          const edgeData = edge.node.frontmatter;
          return (
            <VideoContainer>
              <VideoWrapper key={edgeData.link}>
                <Video
                  src={`https://player.vimeo.com/video/${edgeData.link}?autoplay=0&loop=0&muted=0&title=1&byline=0&portrait=0`}
                  frameBorder="0"
                  width="100%"
                  height="100%"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                ></Video>
              </VideoWrapper>
              <VideoTitle>{edgeData.title}</VideoTitle>
              <VideoByline>{edgeData.byline}</VideoByline>
            </VideoContainer>
          );
        })}
      </VideosContainer>
    </Container>
  );
};

export default Videos;

const Container = styled("div", {
  width: "95%",
  margin: "auto",
});

const HeadingContainer = styled("div", {
  display: "none",
  "@md": {
    display: "block",
  },
});

const Title = styled("h2", {
  ...heading300,
  margin: 0,
});

const Byline = styled("p", {
  ...paragraphTextSmall,
  margin: 0,
});

const VideosContainer = styled("div", {
  margin: "auto",
  marginTop: 26,
  width: "95%",
  "@md": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
  },
});

const VideoWrapper = styled("div", {
  padding: "55.25% 0 0 0",
  position: "relative",
  display: "block",
  // marginRight: 10,
  marginBottom: 10,
  "@md": {
    padding: "55.25% 0 0 0",
    // flex: "1 0 48%",
    borderRadius: 4,
    overflow: "hidden",
  },
});

const Video = styled("iframe", {
  position: "absolute",
  top: 0,
  left: 0,
  "@md": {
    borderRadius: 4,
    overflow: "hidden",
  },
});

const VideoContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginBottom: 30,
  "@md": {
    width: "48%",
  },
});
const VideoTitle = styled("div", {
  ...heading100,
  margin: 0,
});
const VideoByline = styled("div", {
  ...paragraphTextSmall,
  margin: 0,
});
